import { motion, HTMLMotionProps } from 'framer-motion';

const ANIMATIONS_OLD = {
  swipeIn: {
    hidden: { opacity: 0, x: 200, y: 0 },
    enter: { opacity: 1, x: 0, y: 0 },
    exit: { opacity: 0, x: 0, y: -100 },
  },
  swipeTop: {
    hidden: { opacity: 0, x: 0, y: -50 },
    enter: { opacity: 1, x: 0, y: 0 },
    exit: { opacity: 0, x: 0, y: -50 },
  },
  swipeBottom: {
    hidden: { opacity: 0, x: 0, y: 5 },
    enter: { opacity: 1, x: 0, y: 0 },
    exit: { opacity: 0, x: 0, y: 5 },
  },
  scale: {
    hidden: { opacity: 0, scale: 0.8 },
    enter: { opacity: 1, scale: 1 },
    exit: { opacity: 0, scale: 0.8 },
  },
  opacity: {
    hidden: { opacity: 0 },
    enter: { opacity: 1 },
    exit: { opacity: 0 },
  },
};

interface AnimationProps {
  uKey: string;
  variant: keyof typeof ANIMATIONS_OLD;
  children: JSX.Element | JSX.Element[];
  transition?: {
    delay?: number;
    duration?: number;
  };
  style?: object;
}

export const Animation = ({ uKey, variant, children, transition, style }: AnimationProps) => (
  <motion.div
    key={uKey}
    initial='hidden'
    animate='enter'
    exit='exit'
    variants={ANIMATIONS_OLD[variant]}
    transition={{ type: 'ease-in-out', ...transition }}
    style={style}
  >
    {children}
  </motion.div>
);

const ANIMATIONS = {
  swipeRight: {
    hidden: { opacity: 0, x: 10 },
    enter: { opacity: 1, x: 0 },
    exit: { opacity: 0, x: -10 },
  },
  swipeBottom: {
    hidden: { opacity: 0, y: 10 },
    enter: { opacity: 1, y: 0 },
    exit: { opacity: 0, y: -10 },
  },
  opacity: {
    hidden: { opacity: 0 },
    enter: { opacity: 1 },
    exit: { opacity: 0 },
  },
  swipeUp: {
    hidden: { y: 50, opacity: 0 },
    enter: { y: 0, opacity: 1 },
  },
};

export const Animate = ({
  id,
  transition,
  children,
  variant,
  ...props
}: Omit<HTMLMotionProps<'div'>, 'key'> & { id?: string; variant: keyof typeof ANIMATIONS }) => (
  <motion.div
    transition={{ type: 'tween', ...transition }}
    initial='hidden'
    animate='enter'
    exit='exit'
    {...props}
    variants={ANIMATIONS[variant]}
    key={id}
  >
    {children}
  </motion.div>
);
