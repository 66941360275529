import { Box, Text, TextProps } from '@chakra-ui/react';
import { formulas, useDate } from '@shared/common';
import format from 'date-fns/format';
import Link from 'next/link';
import { Trans, useTranslation } from 'next-i18next';
import { FC } from 'react';

import { config } from '~/config';
import { useAppLead } from '~/hooks/useAppLead';

interface TransTextProps extends TextProps {
  transKey?: string;
  values?: { innerStyles?: TextProps; [key: string]: unknown };
  components?: Record<string, JSX.Element>;
}

const DEFAULT_VALUES = {
  currentYear: new Date().getUTCFullYear(),
  projectName: 'Digesti',
  supportEmail: 'hello@digestiplan.com',
};

const DEFAULT_COMPONENTS = {
  mobileBr: <Box as='br' display={{ base: 'block', md: 'none' }} />,
  boldBlack: <Box as='b' color='black' />,
  b: <b></b>,
  br: <br />,
  u: <u></u>,
  ol: <ol></ol>,
  ul: <ul></ul>,
  li: <li></li>,
};

const PLAMSIC_STYLES: TextProps = { wordBreak: 'break-all' };

export const TransText: FC<TransTextProps> = ({ transKey, values, components, ...props }) => {
  const {
    quizAnswers,
    lead: { email },
    gender,
  } = useAppLead();
  const { addDays } = useDate();
  const { t } = useTranslation();
  const unitSystem = quizAnswers?.isMetric ? t('common:kilograms') : t('common:pounds');
  const weight = quizAnswers?.isMetric
    ? Number(formulas.lbsToKg(Number(quizAnswers?.weight)).toFixed(0))
    : Number(Number(quizAnswers?.weight).toFixed(0));

  const targetWeight = quizAnswers?.isMetric
    ? Number(formulas.lbsToKg(Number(quizAnswers?.targetWeight)).toFixed(0))
    : Number(Number(quizAnswers?.targetWeight).toFixed(0));

  const weightLossTimeInMonths = formulas.calcWeightLossTime(
    Number(quizAnswers?.weight),
    Number(quizAnswers?.targetWeight),
  );

  const isMetric = quizAnswers?.isMetric;

  const getValuesFromProps = () => {
    return {
      ...values,
      weightStatic:
        values?.weightStatic && isMetric ? Math.ceil(Number(values?.weightStatic) * 0.45) : values?.weightStatic,
    };
  };

  return (
    <Text as='div' {...{ color: 'inherit', ...props, ...(config.isPlasmicPreview ? PLAMSIC_STYLES : {}) }}>
      {transKey ? (
        <>
          <Trans
            i18nKey={transKey}
            values={{
              units: unitSystem,
              unitsShort: quizAnswers?.isMetric ? 'kg' : 'lbs',
              weightDiff: weight - targetWeight,
              monthsCount: weightLossTimeInMonths,
              age: quizAnswers?.age,
              currentWeight: weight,
              targetWeight,
              count: weightLossTimeInMonths,
              goalDate: format(addDays(weightLossTimeInMonths * 30).newDate, 'LLLL d, yyyy'),
              email,
              gender: gender === 'male' ? t('common:men') : t('common:women'),
              genderAdj: gender === 'male' ? t('common:male') : t('common:female'),
              ...DEFAULT_VALUES,
              ...getValuesFromProps(),
            }}
            components={{
              ...DEFAULT_COMPONENTS,
              span: <Text as='span' {...(values?.innerStyles as TextProps)}></Text>,
              a: <Link href={(values as Record<string, string>)?.href}></Link>,
              ...components,
            }}
          >
            {transKey}
          </Trans>
        </>
      ) : (
        'Enter some text...'
      )}
    </Text>
  );
};
