import { Box, Flex, Text } from '@chakra-ui/react';
import { formulas, useDate } from '@shared/common';
import addMonths from 'date-fns/addMonths';
import format from 'date-fns/format';
import Image from 'next/image';
import { useTranslation } from 'next-i18next';

import WeightLossProgressChart from '~/assets/icons/quiz/weight-loss-progress-chart.svg';
import ScalesSVG from '~/assets/icons/scales.svg';
import SimpleWeightChartSVG from '~/assets/icons/weight-loss-simple-chart.svg';
import WeightLossChartSVG from '~/assets/icons/weight_loss_chart.svg';
import SummaryChartImage from '~/assets/images/summary_chart.png';
import { TransText } from '~/components/TransText';
import { useAppLead } from '~/hooks/useAppLead';
import { useAppRouter } from '~/hooks/useAppRouter';

// const { calcWeightLossTime } = formulas;

export const WeightLostChart = () => {
  const { quizAnswers } = useAppLead();
  // const { addDays } = useDate();

  const unitSystem = quizAnswers?.isMetric ? 'kg' : 'lb';
  const weight = unitSystem === 'kg' ? formulas.lbsToKg(Number(quizAnswers?.weight)) : Number(quizAnswers?.weight);
  const targetWeight =
    unitSystem === 'kg' ? formulas.lbsToKg(Number(quizAnswers?.targetWeight)) : Number(quizAnswers?.targetWeight);
  const answer = quizAnswers?.experience as string;
  const goal = /bloating/.test(answer)
    ? 'Bloating'
    : /constipation/.test(answer)
      ? 'Constipation'
      : /inflammation/.test(answer)
        ? 'Inflammation'
        : /diarrhea/.test(answer)
          ? 'Diarrhea'
          : /brain-fog/.test(answer)
            ? 'Brain fog'
            : /fatigue/.test(answer)
              ? 'Fatigue'
              : /food-intolerance/.test(answer)
                ? 'Food intolerance'
                : 'Bloating';

  // const weightLossTimeInMonths = calcWeightLossTime(Number(quizAnswers?.weight), Number(quizAnswers?.targetWeight));
  // const goalDate = addDays(weightLossTimeInMonths * 30);

  return (
    <>
      <Flex
        padding='0.75rem 1rem'
        border='1px solid rgba(0, 0, 0, 0.05)'
        borderRadius='0.5rem'
        alignItems='center'
        gap='16px'
        marginBottom={{ base: '0.5rem', md: '1rem' }}
      >
        <Flex backgroundColor='#D7FFBE' padding='0.5rem' borderRadius='0.25rem'>
          <ScalesSVG width='32px' height='32px' fill='#308000' />
        </Flex>
        <Box>
          <Text fontWeight={700} fontSize='12px'>
            Fact:
          </Text>
          <Text lineHeight='120%' fontSize='14px'>
            Others who weighted {`${weight} ${unitSystem}`}{' '}
            <Box as='span' color='#03bde1'>
              had a 93% of success rate
            </Box>{' '}
            with our program
          </Text>
        </Box>
      </Flex>
      <Flex
        width='100%'
        border='1px solid rgba(0, 0, 0, 0.06)'
        flex={1}
        backgroundColor='system.white'
        borderRadius='0.25rem'
        padding='1.5rem'
        flexDirection='column'
      >
        <Text textStyle='small' marginBottom='3rem'>
          {`${goal} may be one of the bigest causes that stops you from weight loss`}
        </Text>
        <Flex position='relative'>
          <WeightLossProgressChart width='100%' />
          <Flex
            display='inline-flex'
            flexDirection='column'
            position='absolute'
            top='-40px'
            fontSize='14px'
            left={{ base: '-1%', md: '1%' }}
            width='58px'
            textAlign='center'
            fontWeight={700}
          >
            <Flex
              display='block'
              color='white'
              backgroundColor='black'
              padding='0.5rem'
              borderRadius='0.25rem'
              lineHeight='100%'
              fontSize='12px'
              fontWeight={700}
            >
              {`${weight.toFixed(0)}${unitSystem}`}
            </Flex>
            <Flex position='relative' top='-1px' zIndex={1} justifyContent='center'>
              <svg xmlns='http://www.w3.org/2000/svg' width='20' height='7' viewBox='0 0 20 7' fill='none'>
                <path
                  d='M12.4988 6.00097C11.0379 7.16967 8.96212 7.16968 7.50124 6.00098L0 -1.74846e-06L20 0L12.4988 6.00097Z'
                  fill='black'
                />
              </svg>
            </Flex>
          </Flex>
          <Flex
            display='inline-flex'
            flexDirection='column'
            position='absolute'
            top={{ base: '38%', md: '45%' }}
            fontSize='14px'
            right={{ base: '4%', md: '6%' }}
            fontWeight={700}
            width='52px'
            textAlign='center'
          >
            <Flex
              display='block'
              color='white'
              lineHeight='100%'
              backgroundColor='black'
              padding='0.5rem'
              borderRadius='0.25rem'
              fontSize='12px'
              fontWeight={700}
            >
              {`${targetWeight.toFixed(0)}${unitSystem}`}
            </Flex>
            <Flex position='relative' top='-1px' zIndex={1} justifyContent='center'>
              <svg xmlns='http://www.w3.org/2000/svg' width='20' height='7' viewBox='0 0 20 7' fill='none'>
                <path
                  d='M12.4988 6.00097C11.0379 7.16967 8.96212 7.16968 7.50124 6.00098L0 -1.74846e-06L20 0L12.4988 6.00097Z'
                  fill='black'
                />
              </svg>
            </Flex>
          </Flex>
        </Flex>
      </Flex>
    </>
  );
};

export const WeightLostChartBlue = () => {
  const { quizAnswers } = useAppLead();
  const { year, month, addDays } = useDate();
  const { asPath } = useAppRouter();
  const weight = quizAnswers?.isMetric
    ? Number(formulas.lbsToKg(Number(quizAnswers?.weight)).toFixed(0))
    : Number(Number(quizAnswers?.weight).toFixed(0));

  const targetWeight = quizAnswers?.isMetric
    ? Number(formulas.lbsToKg(Number(quizAnswers?.targetWeight)).toFixed(0))
    : Number(Number(quizAnswers?.targetWeight).toFixed(0));
  const unitSystem = quizAnswers?.isMetric ? 'kg' : 'lb';

  const weightLostAfterMonth =
    formulas.calcFirstMonthWeightLoss(Number(quizAnswers?.weight), Number(quizAnswers?.targetWeight)) / 2;
  const weightLossTimeInMonths = formulas.calcWeightLossTime(
    Number(quizAnswers?.weight),
    Number(quizAnswers?.targetWeight),
  );

  return (
    <>
      <Box
        width={{ base: '104%', md: '100%' }}
        margin={{ base: '0 -2% 0.5rem -2%', md: '0 0 0.5rem 0' }}
        borderRadius='0.5rem'
        border='1px solid rgba(0, 0, 0, 0.15)'
        maxWidth='28rem'
      >
        <Flex
          gap='0.5rem'
          padding={{ base: '1rem', md: '1.5rem 1.5rem 1rem 1.5rem' }}
          justifyContent='center'
          borderBottom='1px solid rgba(0, 0, 0, 0.2)'
          alignItems='center'
        >
          {[-2, -1, 0, 1, 2].map((index) => (
            <>
              {index !== -2 && (
                <Box backgroundColor='rgba(10, 9, 8, 0.24)' width='4px' height='4px' borderRadius='4px'></Box>
              )}
              <Text
                color={index === 0 ? 'system.black' : 'rgba(10, 9, 8, 0.24)'}
                fontSize={{ base: index === 0 ? '32px' : '18px', md: index === 0 ? '32px' : '22px' }}
                lineHeight='110%'
                fontWeight={700}
              >
                {targetWeight + index}
                {index === 0 ? ` ${unitSystem}` : ''}
              </Text>
            </>
          ))}
        </Flex>
        <Flex
          padding={{ base: '0.5rem', md: /trial/.test(asPath) ? '1rem' : '0.5rem 2rem 2rem 2rem' }}
          maxWidth='480px'
          flexDirection='column'
          margin='auto'
        >
          {/trial/.test(asPath) ? null : (
            <Text textAlign='center' fontSize='24px' fontFamily='var(--cabinetGroteskFont)' fontWeight={900}>
              by {addDays(weightLossTimeInMonths * 30).month}{' '}
              {addDays(weightLossTimeInMonths * 30).newDate.getFullYear()}
            </Text>
          )}
          <Flex position='relative' width='100%' marginBottom='0.75rem'>
            <Flex>
              <Image
                src={SummaryChartImage}
                width={483}
                height={302}
                alt=''
                placeholder='blur'
                priority
                style={{
                  maxWidth: '100%',
                  height: 'auto',
                }}
              />
            </Flex>
            <Text position='absolute' fontWeight={700} fontSize='14px' color='system.error' left='9%' top='10%'>
              {weight} {unitSystem}
            </Text>
            <Text position='absolute' fontWeight={700} fontSize='14px' color='system.success' left='82%' top='86%'>
              {targetWeight} {unitSystem}
            </Text>
            <Box position='absolute' bottom='59%' left={{ base: '27%', md: '28%' }}>
              <Text
                padding='0.125rem 0.5rem'
                backgroundColor='system.black'
                borderRadius='0.5rem'
                color='system.white'
                fontSize='14px'
              >
                You will lose first{' '}
                <b>
                  {(quizAnswers?.isMetric
                    ? formulas.lbsToKg(weightLostAfterMonth) < 1
                      ? 1
                      : formulas.lbsToKg(weightLostAfterMonth)
                    : weightLostAfterMonth
                  ).toFixed(0)}
                  {unitSystem}
                </b>
                <br />
                within <b>2 weeks</b>
              </Text>
              <Box
                width='0'
                height='0'
                marginLeft='1.5rem'
                borderLeft='5px solid transparent'
                borderRight='5px solid transparent'
                borderTop='5px solid #0A0908'
              />
            </Box>
          </Flex>
          <Flex marginLeft='2rem' gap='1rem' marginRight='0.5rem' justifyContent='space-between'>
            <Text fontSize='14px'>
              {month} {year}
            </Text>
            <Text fontSize='14px'>
              {addDays(weightLossTimeInMonths * 30).month} {addDays(weightLossTimeInMonths * 30).newDate.getFullYear()}
            </Text>
          </Flex>
        </Flex>
      </Box>
      {/trial/.test(asPath) ? null : (
        <Flex
          padding='0.75rem 1rem'
          border='1px solid rgba(0, 0, 0, 0.05)'
          borderRadius='0.5rem'
          alignItems='center'
          gap='16px'
          maxWidth='28rem'
        >
          <Flex backgroundColor='#D7FFBE' padding='0.5rem' borderRadius='0.25rem'>
            <ScalesSVG width='32px' height='32px' fill='#308000' />
          </Flex>
          <Box>
            <Text fontWeight={700} fontSize='12px'>
              Fact:
            </Text>
            <Text lineHeight='120%' fontSize='14px'>
              Others who weighted {`${weight} ${unitSystem}`}{' '}
              <Box as='span' color='#03bde1'>
                had a 93% of success rate
              </Box>{' '}
              with our program
            </Text>
          </Box>
        </Flex>
      )}
    </>
  );
};

export const WeightLossChartWithoutWrapper = () => {
  const { quizAnswers } = useAppLead();
  const { year, month, addDays } = useDate();
  const weight = quizAnswers?.isMetric
    ? Number(formulas.lbsToKg(Number(quizAnswers?.weight)).toFixed(0))
    : Number(Number(quizAnswers?.weight).toFixed(0));

  const targetWeight = quizAnswers?.isMetric
    ? Number(formulas.lbsToKg(Number(quizAnswers?.targetWeight)).toFixed(0))
    : Number(Number(quizAnswers?.targetWeight).toFixed(0));
  const unitSystem = quizAnswers?.isMetric ? 'kg' : 'lb';

  const weightLostAfterMonth =
    formulas.calcFirstMonthWeightLoss(Number(quizAnswers?.weight), Number(quizAnswers?.targetWeight)) / 2;
  const weightLossTimeInMonths = formulas.calcWeightLossTime(
    Number(quizAnswers?.weight),
    Number(quizAnswers?.targetWeight),
  );

  return (
    <Box width={{ base: '104%', md: '100%' }} margin={{ base: '0 -2% 0.5rem -2%', md: '0 0 0.5rem 0' }}>
      <Text
        textAlign={{ base: 'inherit', md: 'center' }}
        fontSize='20px'
        fontWeight={700}
        lineHeight='130%'
        marginBottom='0.5rem'
      >
        {`Improve your gut health and lose weight by ${addDays(weightLossTimeInMonths * 30).month}`}
      </Text>
      <Text
        fontSize='12px'
        lineHeight='120%'
        maxWidth={{ base: 'none', md: '16rem' }}
        margin={{ base: '0 auto 1rem auto', md: '0 auto 0.5rem auto' }}
        textAlign={{ base: 'inherit', md: 'center' }}
      >
        Assumes you start your program starting within this week.
      </Text>
      <Flex maxWidth='480px' flexDirection='column' margin='auto'>
        <Flex position='relative' width='100%' marginBottom='0.75rem'>
          <Flex>
            <Image
              src={SummaryChartImage}
              width={483}
              height={302}
              alt=''
              placeholder='blur'
              priority
              style={{
                maxWidth: '100%',
                height: 'auto',
              }}
            />
          </Flex>
          <Text position='absolute' fontWeight={700} fontSize='14px' color='system.error' left='9%' top='10%'>
            {weight} {unitSystem}
          </Text>
          <Text position='absolute' fontWeight={700} fontSize='14px' color='system.success' left='82%' top='86%'>
            {targetWeight} {unitSystem}
          </Text>
          <Box position='absolute' bottom='59%' left={{ base: '27%', md: '28%' }}>
            <Text
              padding='0.125rem 0.5rem'
              backgroundColor='system.black'
              borderRadius='0.5rem'
              color='system.white'
              fontSize='14px'
            >
              You will lose first{' '}
              <b>
                {(quizAnswers?.isMetric
                  ? formulas.lbsToKg(weightLostAfterMonth) < 1
                    ? 1
                    : formulas.lbsToKg(weightLostAfterMonth)
                  : weightLostAfterMonth
                ).toFixed(0)}
                {unitSystem}
              </b>
              <br />
              within <b>2 weeks</b>
            </Text>
            <Box
              width='0'
              height='0'
              marginLeft='1.5rem'
              borderLeft='5px solid transparent'
              borderRight='5px solid transparent'
              borderTop='5px solid #0A0908'
            />
          </Box>
        </Flex>
        <Flex marginLeft='2rem' gap='1rem' marginRight='0.5rem' justifyContent='space-between'>
          <Text fontSize='14px'>
            {month} {year}
          </Text>
          <Text fontSize='14px'>
            {addDays(weightLossTimeInMonths * 30).month} {addDays(weightLossTimeInMonths * 30).newDate.getFullYear()}
          </Text>
        </Flex>
      </Flex>
    </Box>
  );
};

export const SimpleWeightLossChart = () => {
  const { t } = useTranslation();
  const { quizAnswers, selectedPlan } = useAppLead();
  const { addDays } = useDate();

  const weight = quizAnswers?.isMetric
    ? Number(formulas.lbsToKg(Number(quizAnswers?.weight)).toFixed(0))
    : Number(Number(quizAnswers?.weight).toFixed(0));
  const targetWeight = quizAnswers?.isMetric
    ? Number(formulas.lbsToKg(Number(quizAnswers?.targetWeight)).toFixed(0))
    : Number(Number(quizAnswers?.targetWeight).toFixed(0));
  const unitSystem = quizAnswers?.isMetric ? 'kg' : 'lb';

  return (
    <>
      <Box display='flex' flexDir='column' width='100%' justifyContent='center'>
        <Flex
          position='relative'
          sx={{ '> svg': { width: { base: '320px', md: '440px' }, height: { base: '130px', md: '180px' } } }}
          marginBottom='0.5rem'
        >
          <Text position='absolute' top='-0.25rem' left='0.25rem' zIndex={10} fontSize='13px'>
            {weight}
            {unitSystem}
          </Text>

          <Text
            position='absolute'
            fontSize={{ base: '11px', md: '12px' }}
            color='#fff'
            bottom={{ base: '16%', md: '20%' }}
            right={{ base: 0, md: '0' }}
            zIndex={12}
            bg='#C89207'
            lineHeight='100%'
            padding='0.25rem 0.5rem'
            borderRadius='0.25rem'
          >
            {t('upsells:not_sticking_with_program')}
          </Text>
          <Box position='absolute' top='0' bottom='0' backgroundColor='#e2e3ee' width='1px' left='0' />
          <Box position='absolute' top='0' bottom='0' backgroundColor='#e2e3ee' width='1px' left='25%' />
          <Box position='absolute' top='0' bottom='0' backgroundColor='#e2e3ee' width='1px' left='50%' />
          <Box position='absolute' top='0' bottom='0' backgroundColor='#e2e3ee' width='1px' left='75%' />
          <Box position='absolute' top='0' bottom='0' backgroundColor='#e2e3ee' width='1px' right={0} />
          <Box
            position='absolute'
            top={{ base: '15%', md: '24%' }}
            left={{ base: '41%', md: '44%' }}
            backgroundColor='#3FA134'
            padding='0.25rem 0.5rem'
            borderRadius='0.5rem'
            zIndex={12}
          >
            <Text textAlign='center' color='white' fontSize='13px' lineHeight='130%'>
              {t('common:now')}
              <br />
              {targetWeight} {unitSystem}
            </Text>
          </Box>
          <SimpleWeightChartSVG style={{ position: 'relative', zIndex: 10 }} />
        </Flex>
        <Flex justifyContent='space-between'>
          <TransText
            transKey='common:now'
            textTransform='uppercase'
            fontSize='13px'
            fontWeight={500}
            left='0.75rem'
            zIndex={10}
          ></TransText>
          <Text
            textTransform='uppercase'
            right={{ base: 0, md: '0.75rem' }}
            zIndex={10}
            fontSize='13px'
            fontWeight={500}
            marginLeft='15%'
          >
            {format(addDays(30 * (selectedPlan?.plan.interval || 6)).newDate, 'MMMM')}
          </Text>
          <Text
            textTransform='uppercase'
            right={{ base: 0, md: '0.75rem' }}
            zIndex={10}
            fontSize='13px'
            fontWeight={500}
          >
            {format(addMonths(new Date(), 12), 'MMMM, yyyy')}
          </Text>
        </Flex>
      </Box>
    </>
  );
};

export const WeightLossChartComparison = () => {
  const { quizAnswers } = useAppLead();
  const isMetric = quizAnswers?.isMetric;
  const weight = Number(quizAnswers?.weight);
  const targetWeight = Number(quizAnswers?.targetWeight);
  const units = isMetric ? 'kg' : 'lb';
  const timeInMonths = formulas.calcWeightLossTime(weight, targetWeight);

  return (
    <Box width='100%'>
      <Flex position='relative' marginBottom='0.375rem' borderBottom='1px solid rgba(0, 0, 0, 0.1)'>
        <Box position='absolute' padding='0.375rem 0.75rem' borderRadius='2rem' bg='black' top={1} left={-2}>
          <Text color='white' lineHeight='100%'>
            {`${isMetric ? formulas.lbsToKg(weight).toFixed(0) : weight} ${units}`}
          </Text>
        </Box>
        <Box position='absolute' padding='0.375rem 0.75rem' borderRadius='2rem' bg='black' bottom={8} right={-2}>
          <Text color='white' lineHeight='100%'>
            {`${isMetric ? formulas.lbsToKg(targetWeight).toFixed(0) : targetWeight} ${units}`}
          </Text>
        </Box>
        <WeightLossChartSVG width='100%' height='100%' viewBox='0 0 295 170' />
      </Flex>
      <Flex justifyContent='space-between' alignItems='center'>
        <Text color='black60' fontSize='12px'>
          Today
        </Text>
        <Text color='black60' fontSize='12px'>
          {`Day ${timeInMonths * 28}+`}
        </Text>
      </Flex>
      <Flex justifyContent='space-between' alignItems='center'>
        <Text fontWeight={500}>{format(new Date(), 'MMMM yyyy')}</Text>
        <Text fontWeight={500}>{format(addMonths(new Date(), timeInMonths), 'MMMM yyyy')}</Text>
      </Flex>
    </Box>
  );
};
