import { Box, BoxProps } from '@chakra-ui/react';
import { Animate } from '@shared/animations';
import { ForwardedRef, forwardRef, ReactNode } from 'react';

export const StickyWrapper = forwardRef(
  ({ children, ...styles }: { children: ReactNode } & BoxProps, ref: ForwardedRef<HTMLDivElement>) => (
    <Box
      ref={ref}
      position={{ base: 'fixed', md: 'sticky' }}
      bottom='0'
      left='0'
      right='0'
      margin={{ md: '0', base: 'auto' }}
      backgroundColor='rgba(255, 255, 255, 0.8)'
      borderTop={{ base: '1px solid rgba(0, 0, 0, 0.1)', md: 'none' }}
      backdropFilter='blur(4px)'
      padding={{ base: '0.5rem 6%', md: '1rem 0' }}
      boxShadow={{ base: '0px 4px 4px 2px rgba(0, 0, 0, 0.2)', md: 'none' }}
      {...styles}
    >
      <Animate variant='swipeBottom' transition={{ delay: 0.5 }}>
        {children}
      </Animate>
    </Box>
  ),
);

StickyWrapper.displayName = 'StickyWrapper';
